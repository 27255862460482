.commit-reminder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  &-dialog {
    background-color: #fff;
    border-radius: 8px;
    height: fit-content;
    width: 480px;
    max-width: 100%;
    padding: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .description {
      font-size: 2rem;
    }

    .action-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 36px;

      button.btn {
        margin: 0;
      }
    }
    .wedge-checkbox-container {
      font-size: 1em;
      padding-left: 24px;
      span.checkmark {
        height: 16px;
        width: 16px;
        &::after {
          left: 5px;
          top: 1px;
          width: 7px;
          height: 12px;
        }
      }
      p.check_title {
        color: #333;
        font-size: 12px;
      }
    }

    @media (max-width: 480px) {
      height: auto;
      .description {
        font-size: 1em;
      }
    }
  }
}
